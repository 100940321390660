const Skills = () => {
  return (
    <div id="Skills">
      <h1>Skills</h1>
      <p>HTML</p>
      <p>CSS</p>
      <p>JavaScript</p>
      <p>React</p>
      <p>Node.js</p>
      <p>Express</p>
      <p>MongoDB</p>
    </div>
  );
};

export default Skills;
