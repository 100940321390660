const About = () => {
  return (
    <div id="About">
      <h1>About</h1>
      <p>
        Hello! My name is Urvil. I love to create wesites on the internet. I've
        been interested in creating things online since 2020. I like making
        front and back end connections and developing big projects.
      </p>
    </div>
  );
};

export default About;
